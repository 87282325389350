<template>
  <a-modal title="加签" :width="600" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="当前节点" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        {{ this.recordData.name }}
      </a-form-item>
      <a-form-item label="加签用户" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        <a-select
          mode="multiple"
          style="width: 100%"
          placeholder="请选择用户"
          v-decorator="['assigneeList', { rules: [{ required: true, message: '请选择加签用户' }] }]"
        >
          <a-select-option v-for="(item, index) in userList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="加签原因" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        <a-textarea
          :rows="4"
          placeholder="请输入加签原因"
          v-decorator="['comment', { rules: [{ required: true, message: '请输入委托原因' }] }]"
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as Request from '@/api/starter';

export default {
  name: 'AddSign',
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      tableLoading: false,
      recordData: {},
      userList: [],
      form: this.$form.createForm(this)
    };
  },
  methods: {
    /**
     * 打开提交窗口
     *
     * @author fengshuonan
     * @date 2021/7/22 21:14
     */
    open(record) {
      this.recordData = record;
      this.visible = true;

      // 获取用户列表
      this.getUserList();
    },

    /**
     * 获取用户列表
     *
     * @author fengshuonan
     * @date 2021/8/3 21:20
     */
    getUserList() {
      Request.findAddSignUserSelector({ id: this.recordData.procInsId }).then(res => {
        this.userList = res.data;
      });
    },

    /**
     * 提交加签
     *
     * @author fengshuonan
     * @date 2021/7/22 21:15
     */
    handleSubmit() {
      let formData = {};

      formData.taskId = this.recordData.taskId;

      this.form.validateFields((err, values) => {
        if (!err) {
          // 设置审批意见和加签用户
          formData.comment = values.comment;
          formData.assigneeList = values.assigneeList;

          // 更改按钮加载中
          this.confirmLoading = true;

          // 提交加签用户
          Request.addSignForm(formData)
            .then(() => {
              this.confirmLoading = false;

              // 更改按钮加载中
              this.$message.success('加签成功');
              this.visible = false;
              window.parent.postMessage({ action: 'close' }, '*');
            })
            .catch(() => {
              this.confirmLoading = false;
              this.$message.error('审批失败');
            });
        }
      });
    },

    /**
     * 关闭
     *
     * @author fengshuonan
     * @date 2021/7/22 21:16
     */
    handleCancel() {
      this.visible = false;
    }
  }
};
</script>