<template>
  <div>
    <div class="button-area">
      <a-button @click="handleOpenSubmit" type="primary" class="push-button" :disabled="buttonList.submitFlag !== 'Y'">提交</a-button>
      <a-button @click="handleOpenBack" class="reset-button" :disabled="buttonList.backFlag !== 'Y'">退回</a-button>
      <a-button @click="handleOpenTurn" class="reset-button" :disabled="buttonList.turnFlag !== 'Y'">转办</a-button>
      <a-button @click="handleOpenEntrust" class="reset-button" :disabled="buttonList.entrustFlag !== 'Y'">委托</a-button>
      <a-button @click="handleOpenEnd" class="reset-button" :disabled="buttonList.endFlag !== 'Y'">终止</a-button>
      <a-popconfirm ok-text="确定" cancel-text="取消" placement="top" :title="'确定挂起该任务？'" @confirm="() => handleSuspend()">
        <a-button class="reset-button" :disabled="buttonList.suspendFlag !== 'Y'">挂起</a-button>
      </a-popconfirm>
      <a-popconfirm></a-popconfirm>
      <a-button @click="handleOpenJump" class="reset-button" :disabled="buttonList.jumpFlag !== 'Y'">跳转</a-button>
      <a-button @click="handleOpenAddSign" class="reset-button" :disabled="buttonList.addSignFlag !== 'Y'">加签</a-button>
      <a-button @click="handleOpenDeleteSign" class="reset-button" :disabled="buttonList.deleteSignFlag !== 'Y'">减签</a-button>
      <a-button @click="handlePrintRow" class="reset-button">打印</a-button>
    </div>
    <div>
      <!-- 提交界面 -->
      <submit ref="submit" v-if="buttonList.submitFlag === 'Y'" />

      <!-- 退回界面 -->
      <back ref="back" v-if="buttonList.backFlag === 'Y'" />

      <!-- 转办界面 -->
      <turn ref="turn" v-if="buttonList.turnFlag === 'Y'" />

      <!-- 委托界面 -->
      <entrust ref="entrust" v-if="buttonList.entrustFlag === 'Y'" />

      <!-- 终止界面 -->
      <end ref="end" v-if="buttonList.endFlag === 'Y'" />

      <!-- 跳转界面 -->
      <jump ref="jump" v-if="buttonList.jumpFlag === 'Y'" />

      <!-- 加签界面 -->
      <add-sign ref="addSign" v-if="buttonList.addSignFlag === 'Y'" />

      <!-- 减签界面 -->
      <delete-sign ref="deleteSign" v-if="buttonList.deleteSignFlag === 'Y'" />
    </div>
    <div id="printContent">
      <k-form-build :value="jsonData" ref="process_f" />
    </div>
  </div>
</template>

<script>
import * as Request from '@/api/starter';
import store from 'storejs';
import { Button } from 'ant-design-vue';
import { Popconfirm } from 'ant-design-vue';
import Vue from 'vue';
import Back from '@/views/handle/back';
import Submit from '@/views/handle/submit';
import Turn from '@/views/handle/turn';
import Entrust from '@/views/handle/entrust';
import End from '@/views/handle/end';
import Jump from '@/views/handle/jump';
import AddSign from '@/views/handle/addSign';
import DeleteSign from '@/views/handle/deleteSign';
import Print from 'print-js'

Vue.use(Button);
Vue.use(Popconfirm);

export default {
  name: 'FormProcessTask',
  // eslint-disable-next-line vue/no-unused-components
  components: { DeleteSign, AddSign, Jump, End, Entrust, Turn, Submit, Back, Popconfirm },
  data() {
    return {
      jsonData: {},
      buttonList: {},
      recordData: {}
    };
  },
  created() {
    const token = this.$route.params.token;
    store.set('FormVueToken', token);

    const processDefinitionId = this.$route.params.processDefinitionId;
    const actId = this.$route.params.actId;
    const taskId = this.$route.params.taskId;
    const name = this.$route.params.name;
    const procInsId = this.$route.params.procInsId;

    this.recordData = { processDefinitionId, actId, taskId, name, procInsId };

    // 获取表单的元数据
    Request.taskFormData({ processDefinitionId, actId }).then(res => {
      this.jsonData = JSON.parse(res.data);

      // 获取表单的内容数据
      Request.taskData({ processDefinitionId, actId, taskId }).then(res => {
        this.$refs.process_f.setData(JSON.parse(res.data.formData));
      });
    });

    // 获取当前界面是否需要渲染一系列按钮
    Request.buttonTrace({ processDefinitionId, actId }).then(res => {
      this.buttonList = res.data;
    });
  },
  methods: {
    /**
     * 提交办理
     *
     * @author fengshuonan
     * @date 2021/7/22 18:12
     */
    handleOpenSubmit() {
      this.$refs.process_f.getData().then(res => {
        const formData = {};
        formData.formData = JSON.stringify(res);
        this.$refs.submit.open(this.recordData, this.buttonList, formData);
      });
    },

    /**
     * 退回
     *
     * @author fengshuonan
     * @date 2021/7/22 18:12
     */
    handleOpenBack() {
      this.$refs.process_f.getData().then(res => {
        const formData = {};
        formData.formData = JSON.stringify(res);
        this.$refs.back.open(this.recordData, this.buttonList, formData);
      });
    },

    /**
     * 转办
     *
     * @author fengshuonan
     * @date 2021/7/22 18:13
     */
    handleOpenTurn() {
      this.$refs.process_f.getData().then(() => {
        this.$refs.turn.open(this.recordData);
      });
    },

    /**
     * 委托
     *
     * @author fengshuonan
     * @date 2021/7/22 18:13
     */
    handleOpenEntrust() {
      this.$refs.process_f.getData().then(() => {
        this.$refs.entrust.open(this.recordData);
      });
    },

    /**
     * 终止
     *
     * @author fengshuonan
     * @date 2021/7/22 18:14
     */
    handleOpenEnd() {
      this.$refs.process_f.getData().then(() => {
        this.$refs.end.open(this.recordData);
      });
    },

    /**
     * 挂起
     *
     * @author fengshuonan
     * @date 2021/7/22 18:14
     */
    handleSuspend() {
      this.$refs.process_f.getData().then(() => {
        let formData = {};
        formData.taskId = this.recordData.taskId;

        // 提交挂起
        Request.suspendForm(formData).then(() => {
          this.$message.success('挂起成功');
          window.parent.postMessage({ action: 'close' }, '*');
        });
      });
    },

    /**
     * 跳转
     *
     * @author fengshuonan
     * @date 2021/7/22 18:14
     */
    handleOpenJump() {
      this.$refs.process_f.getData().then(() => {
        this.$refs.jump.open(this.recordData);
      });
    },

    /**
     * 加签
     *
     * @author fengshuonan
     * @date 2021/7/22 18:14
     */
    handleOpenAddSign() {
      this.$refs.process_f.getData().then(() => {
        this.$refs.addSign.open(this.recordData);
      });
    },

    /**
     * 减签
     *
     * @author fengshuonan
     * @date 2021/7/22 18:14
     */
    handleOpenDeleteSign() {
      this.$refs.process_f.getData().then(() => {
        this.$refs.deleteSign.open(this.recordData);
      });
    },

    /**
     * 打印
     *
     * @author fengshuonan
     * @date 2021/7/22 18:14
     */
    handlePrintRow() {
      Print({ printable: 'printContent', type: 'html', targetStyles: ['*'] })
    }
  }
};
</script>

<style scoped>
.button-area {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.button-area .reset-button {
  margin-left: 10px;
  border-radius: 2px;
}

.button-area .push-button {
  border-radius: 2px;
}
</style>