<template>
  <a-modal title="提交" :width="600" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item label="当前节点" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        {{ this.recordData.name }}
      </a-form-item>
      <a-form-item label="审批意见" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
        <a-textarea
          :rows="4"
          placeholder="请输入审批意见"
          v-decorator="['comment', { rules: [{ required: true, message: '请输入审批意见' }] }]"
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as Request from '@/api/starter';

export default {
  name: 'submit',
  data() {
    return {
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      visible: false,
      confirmLoading: false,
      recordData: {},
      buttonList: {},
      formDataReq: {}
    };
  },
  methods: {
    /**
     * 打开提交窗口
     *
     * @author fengshuonan
     * @date 2021/7/22 21:14
     */
    open(record, buttonList, formDataReq) {
      this.formDataReq = formDataReq;
      this.buttonList = buttonList;
      this.recordData = record;
      this.visible = true;
    },

    /**
     * 提交待办任务
     *
     * @author fengshuonan
     * @date 2021/7/22 21:15
     */
    handleSubmit() {
      let formData = {};

      formData.processDefinitionId = this.recordData.processDefinitionId;
      formData.taskId = this.recordData.taskId;
      formData.variables = this.formDataReq;

      this.form.validateFields((err, values) => {
        if (!err) {
          // 设置审批意见
          formData.comment = values.comment;

          // 更改按钮加载中
          this.confirmLoading = true;

          // 获取表单的内容数据
          Request.submitForm(formData)
            .then(() => {
              // 更改按钮加载中
              this.$message.success('审批成功');
              this.visible = false;
              this.confirmLoading = false;
              window.parent.postMessage({ action: 'close' }, '*');
            })
            .catch(() => {
              this.confirmLoading = false;
              this.$message.error('审批失败');
            });
        }
      });
    },

    /**
     * 关闭
     *
     * @author fengshuonan
     * @date 2021/7/22 21:16
     */
    handleCancel() {
      this.visible = false;
    }
  }
};
</script>